import React from 'react';
import { Button, Row } from 'antd';
import { navigate } from 'gatsby';
import './BannerSectionButtons.less';
import { ROUTES } from '../../constants/routes';

const BannerSectionButtons = () => {
  return (
    <div className="banner-section-btn-wrapper">
      <Row>
        <Button
          className="banner-section-btn"
          onClick={() => navigate(ROUTES.COURSE)}
        >
          Zobacz kursy
        </Button>
      </Row>
    </div>
  );
};

export default BannerSectionButtons;
