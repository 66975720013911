import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import './TrySection.less';
import FreeLessonButton from './FreeLessonButton/FreeLessonButton';

const { Title, Paragraph } = Typography;

const TrySection: FC = () => {
  const { isTablet } = useMediaQuery();

  return (
    <section className="section-content-container">
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 11 }}>
          <Title level={3} className="try-section-title">
            Wypróbuj <span>za darmo</span>
          </Title>
          <Paragraph className="try-section-text">
            Zastanawiasz się, czy warto? Zobacz przykładowe lekcje i przekonaj
            się! Każda pierwsza lekcja kursu jest dostępna za darmo.
          </Paragraph>
          {isTablet && <FreeLessonButton />}
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 11, offset: 2 }}>
          <video className="video-container" id="2" controls>
            <source
              src="https://wosbe.vicoop.com/uploads/Chmury_64759_1032411892.mp4"
              type="video/mp4"
            />
          </video>
        </Col>
      </Row>
      {!isTablet && <FreeLessonButton />}
    </section>
  );
};

export default TrySection;
