import React from 'react';
import { navigate } from 'gatsby';
import { Button, Row, Typography, Image } from 'antd';
import './RegistrationSection.less';
import { ROUTES } from '../../constants/routes';
import image from '../../images/BannerHomePageBottom.svg';

export const RegistrationSection = () => {
  const { Title, Text } = Typography;

  return (
    <section className="registration-section">
      <div className="registration-section-container">
        <Title level={3} className="registration-section-title">
          Wbijasz na pokład?
        </Title>
        <Text className="registration-section-text">
          Zacznij już teraz. Załóż konto i bądź o krok bliżej do świetnego
          wyniku na maturze.
        </Text>
        <Row align={'stretch'} onClick={() => navigate(ROUTES.SIGN_UP)}>
          <Button type={'primary'}>Załóż konto</Button>
        </Row>
      </div>
      <div className="registration-section-image-container">
        <Image
          alt="Registration Section Image"
          className="registration-section-image"
          src={image}
          preview={false}
        />
      </div>
    </section>
  );
};
