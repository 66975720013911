import React from 'react';
import { Image } from 'antd';
import './BannerSection.less';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import image from '../../images/BannerHomePage.svg';
import BannerSectionButtons from './BannerSectionButtons';
import BannerSectionHeader from './BannerSectionHeader';

export const BannerSection = () => {
  const { isMobile } = useMediaQuery();

  return (
    <section className="banner-section">
      <div className="banner-section-container">
        <BannerSectionHeader />
        {!isMobile && <BannerSectionButtons />}
        <div className="banner-image-container">
          <Image
            alt="Home Banner Image"
            className="banner-image"
            src={image}
            preview={false}
          />
          {isMobile && <BannerSectionButtons />}
        </div>
      </div>
    </section>
  );
};
