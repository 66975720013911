import React from 'react';
import { Typography, Image } from 'antd';
import './ProductSectionCardContent.less';
import { ProductSectionSingleDataProp } from '../../../types/index';

const { Title, Text } = Typography;

type ProductSectionCardContentProp = {
  image: string;
  singleData: ProductSectionSingleDataProp;
};

const ProductSectionCardContent = (data: ProductSectionCardContentProp) => {
  return (
    <>
      <div className="image-container">
        <Image alt="Product image" src={data.image} preview={false} />
      </div>
      <Title level={4}>{data.title}</Title>
      <Text>{data.description}</Text>
    </>
  );
};
export default ProductSectionCardContent;
