import { Button } from 'antd';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { ROUTES } from '../../../constants/routes';
import './FreeLessonButton.less';

const FreeLessonButton = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiCourse {
        nodes {
          slug
        }
      }
    }
  `);

  const freeLessonPath = `${ROUTES.COURSE}/${data.allStrapiCourse.nodes[0].slug}/lekcja1`;

  return (
    <Button
      className="try-section-btn"
      onClick={() => navigate(freeLessonPath)}
    >
      Sprawdź lekcje
    </Button>
  );
};

export default FreeLessonButton;
