import { Card, Carousel, Col, Row, Typography, Image } from 'antd';
import React from 'react';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import './MarketingSection.less';
import { StaticQuery, graphql } from 'gatsby';
import { SingleCardProp } from '../../types/index';
import { MarketingSectionProps } from '../../types/index';

const MarketingSection = () => {
  const { Title, Text } = Typography;
  const { isMobile } = useMediaQuery();
  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const MarketingSectionContent = (data: MarketingSectionProps) => {
    return data?.strapiHomepage?.heroSections?.map(
      (singleCard: SingleCardProp, singleCardIndex: number) => {
        const image = singleCard.banner.localFile.url;
        return (
          <Col key={singleCardIndex} xs={{ span: 24 }} sm={{ span: 6 }}>
            <Card
              key={singleCardIndex}
              bordered={false}
              className="card-style"
              cover={
                <Image
                  alt="Marketing image"
                  src={image}
                  preview={false}
                  className="image"
                />
              }
            >
              <Title level={4} className="card-title">
                {singleCard?.slogan}
              </Title>
              <Text className="card-text">{singleCard?.description}</Text>
            </Card>
          </Col>
        );
      }
    );
  };
  return (
    <StaticQuery
      query={graphql`
        query MarketingSection {
          strapiHomepage {
            heroSections {
              slogan
              description
              banner {
                localFile {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className="marketing-section">
          <Title level={3} className="marketing-section-title">
            Dlaczego możesz nam zaufać?
          </Title>
          {!isMobile ? (
            <Row justify="space-between" className="section-content-container">
              {MarketingSectionContent(data)}
            </Row>
          ) : (
            <div className="marketing-section-carousel">
              <Carousel {...carouselProps} className="carousel-dots">
                {MarketingSectionContent(data)}
              </Carousel>
            </div>
          )}
        </section>
      )}
    />
  );
};

export default MarketingSection;
