import React from 'react';
import { Typography } from 'antd';
import './BannerSectionHeader.less';

const { Title, Text } = Typography;

const BannerSectionHeader = () => {
  return (
    <>
      <Title level={2} className="banner-section-heading">
        Cała wiedza <span>o społeczeństwie</span> w jednym miejscu.
      </Title>
      <Text className="banner-section-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </Text>
    </>
  );
};

export default BannerSectionHeader;
