import React from 'react';
import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import { ROUTES } from '../../constants/routes';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import ProductSectionCardContent from '../../components/ProductsSection/ProductSectionComponents/ProductSectionCardContent';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import './ProductsSection.less';

export const ProductsSection = props => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProduct {
        nodes {
          description {
            data {
              description
            }
          }
          name
          productId
          picture {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  const productsData = data.allStrapiProduct.nodes;

  const { isTablet, isMobile, isDesktop } = useMediaQuery('(min-width: 992px)');

  const renderButton = (
    <Row justify="center" className="products-section-button-row">
      <Button onClick={() => navigate(ROUTES.PRODUCTS)}>Zobacz więcej</Button>
    </Row>
  );

  const renderProducts = productsData.map(product => {
    const image = product.picture.localFile.url;
    const title = product.name;
    const description = product.description?.data?.description;
    const id = product.productId;
    const productPath = `${ROUTES.PRODUCTS}?${id}`;
    return (
      <>
        <Col key={id} span={isDesktop || isTablet ? 5 : 24}>
          <Link to={productPath}>
            <Card className="card-style product-section-card-style">
              <ProductSectionCardContent
                image={image}
                title={title}
                description={description}
              />
              {isMobile && renderButton}
            </Card>
          </Link>
        </Col>
      </>
    );
  });

  const { Title } = Typography;

  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const checkIfCourse = () => (props.isCourseSection ? 'course' : null);

  return (
    <section className={`product-section ${checkIfCourse()}`}>
      <Title level={3} className={`product-section-title ${checkIfCourse()}`}>
        Nasze produkty
      </Title>
      <div className="product-section-content-container">
        {isTablet || isDesktop ? (
          <>
            <Row
              justify={'space-between'}
              className="section-content-container"
            >
              {renderProducts}
            </Row>
            {renderButton}
          </>
        ) : (
          <Carousel {...carouselProps} className="carousel-dots">
            {renderProducts}
          </Carousel>
        )}
      </div>
    </section>
  );
};
