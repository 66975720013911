import React, { useRef } from 'react';
import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import './TestimonialSection.less';
import { UserOutlined } from '@ant-design/icons';
import useMediaQuery from '../../utils/hooks/useMediaQuery';

export const TestimonialSection = () => {
  const carouselRef = useRef(null);
  const { isTablet, isDesktop } = useMediaQuery();
  const { Meta } = Card;

  const { Title, Text } = Typography;

  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isDesktop ? 3 : isTablet ? 2 : 1,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonial-section">
      <div className="testimonial-section-contentContainer">
        <Title level={3} className="testimonial-section-title">
          Sprawdź, co o nas piszą
        </Title>
        <div>
          <Row gutter={16} align="middle" justify="space-between">
            {isDesktop && (
              <Col
                className="gutter-row testimonial-section-colCenter"
                span={1.5}
              >
                <Button
                  icon={
                    <svg
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.31219 1.4107V0.20289C8.31219 0.098202 8.19187 0.0403895 8.11062 0.104452L1.06687 5.60601C1.00703 5.65256 0.958602 5.71215 0.925291 5.78025C0.89198 5.84836 0.874664 5.92317 0.874664 5.99898C0.874664 6.0748 0.89198 6.14961 0.925291 6.21771C0.958602 6.28582 1.00703 6.34541 1.06687 6.39195L8.11062 11.8935C8.19344 11.9576 8.31219 11.8998 8.31219 11.7951V10.5873C8.31219 10.5107 8.27625 10.4373 8.21688 10.3904L2.59187 5.99976L8.21688 1.60758C8.27625 1.5607 8.31219 1.48726 8.31219 1.4107Z"
                        fill="white"
                      />
                    </svg>
                  }
                  onClick={() => carouselRef.current?.prev()}
                />
              </Col>
            )}
            <Col className="gutter-row" span={isDesktop ? 21 : 24}>
              <Carousel
                className="testimonial-section-carousel carousel-dots"
                ref={carouselRef}
                {...carouselProps}
              >
                {Array.from<unknown, React.ReactNode>(
                  { length: 4 },
                  (_, index) => (
                    <div
                      className="testimonial-section-slider-wrapper"
                      key={index}
                    >
                      <Card className="testimonial-section-card">
                        <Meta
                          avatar={
                            <UserOutlined className="testimonial-section-card-avatar" />
                          }
                          title="User name"
                        />
                        <Text className="testimonial-section-card-text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </Text>
                      </Card>
                    </div>
                  )
                )}
              </Carousel>
            </Col>
            {isDesktop && (
              <Col
                className="gutter-row testimonial-section-colCenter"
                span={1.5}
              >
                <Button
                  icon={
                    <svg
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.96405 5.60637L0.920313 0.104809C0.901908 0.0903191 0.879788 0.081314 0.856495 0.0788282C0.833203 0.0763424 0.809681 0.0804768 0.788632 0.0907565C0.767583 0.101036 0.749861 0.117045 0.7375 0.136943C0.725139 0.156841 0.71864 0.179822 0.718751 0.203247V1.41106C0.718751 1.48762 0.754689 1.56106 0.814064 1.60793L6.43905 6.00012L0.814064 10.3923C0.753126 10.4392 0.718751 10.5126 0.718751 10.5892V11.797C0.718751 11.9017 0.839063 11.9595 0.920313 11.8954L7.96405 6.39387C8.02391 6.34717 8.07234 6.28744 8.10565 6.21921C8.13896 6.15098 8.15628 6.07605 8.15628 6.00012C8.15628 5.92419 8.13896 5.84927 8.10565 5.78104C8.07234 5.71281 8.02391 5.65307 7.96405 5.60637Z"
                        fill="white"
                      />
                    </svg>
                  }
                  onClick={() => carouselRef.current?.next()}
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </section>
  );
};
