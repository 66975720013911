import React from 'react';
import './index.less';
import { Layout } from '../components/Layout/Layout';
import { BannerSection } from '../components/BannerSection/BannerSection';
import { TestimonialSection } from '../components/TestimonialSection/TestimonialSection';
import MarketingSection from '../components/MarketingSection/MarketingSection';
import { ProductsSection } from '../components/ProductsSection/ProductsSection';
import { RegistrationSection } from '../components/RegistrationSection/RegistrationSection';
import TrySection from '../components/TrySection/TrySection';

const IndexPage = () => {
  return (
    <Layout>
      <main className="index-wrapper">
        <BannerSection />
        <MarketingSection />
        <ProductsSection />
        <div className="about-try-section">
          <TrySection />
        </div>
        <TestimonialSection />
        <RegistrationSection />
      </main>
    </Layout>
  );
};

export default IndexPage;
